import PortalPageBox from "@portal/components/atoms/portalPageBox";
import LayoutNotAuthorized from "@portal/components/organisms/Layout-NotAuthorized";
import Typography from "@portal/components/atoms/typography";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import { TextLine } from "@portal/components/atoms/textLine";
import { ButtonComponent } from "@portal/components/atoms/buttons/main/ButtonComponent";
import { TextResources } from "@portal/assets/text/TextResources";
import { FC } from "react";
import { ISignInPageProps } from "./types";
import { trackMessage } from "@portal/appInsights";

const SignInPage: FC<ISignInPageProps> = ({ signInPath }) => {
  const router = useRouter();

  if (!router.query.error) return null;

  trackMessage("At signin page");

  return (
    <LayoutNotAuthorized>
      <PortalPageBox>
        <div className="space-y-3">
          <div>
            <Typography variant="H6">{TextResources.SIGNIN_ERROR_HEADER}</Typography>
            <TextLine />
          </div>
          <div>
            <Typography variant="Body">{TextResources.SIGNIN_ERROR_PAGE_TEXT}</Typography>
          </div>
          <div>
            <ButtonComponent
              text={TextResources.SIGNIN_ERROR_LOGIN_BUTTON}
              wide
              onClick={async () => {
                await signOut({ callbackUrl: `${process.env.NEXT_PUBLIC_APPLICATION_BASE_URL}${signInPath}` });
              }}
            />
          </div>
        </div>
      </PortalPageBox>
    </LayoutNotAuthorized>
  );
};

export default SignInPage;
