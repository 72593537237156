import useSignIn from "@portal/hooks/useSignIn";
import SignInPage from "@portal/components/pages/signInPage";

const Signin = () => {
  useSignIn();

  return SignInPage({ signInPath: "/auth/signin" });
};

export default Signin;
