import { useRouter } from "next/router";
import { ISignInOptions, IUseSignIn } from "./types";
import { SignInAuthorizationParams, signIn, useSession } from "next-auth/react";
import { useEffect } from "react";
import { trackMessage } from "@portal/appInsights";

const useSignIn: IUseSignIn = () => {
  const router = useRouter();
  const { status, data } = useSession();

  trackMessage(`useSignIn ${status}`);

  useEffect(() => {
    if (router.isReady && !router.query.error) {
      if (status === "unauthenticated") {
        const signInOptions = {} as ISignInOptions;
        const url = router.query.callbackUrl
          ? new URL(router.query.callbackUrl as string)
          : new URL(`${process.env.NEXT_PUBLIC_APPLICATION_BASE_URL}/`);
        const centerId = url.searchParams.get("centerId");
        const startoverurl = router.query.startoverurl as string;

        if (centerId) {
          signInOptions.logonwithcenterid = centerId;
        }
        const extraParams = new URLSearchParams();
        Object.keys(router.query).forEach(key => {
          if (key !== "startoverurl" && key !== "callbackUrl") {
            extraParams.append(key, router.query[key] as string);
            url.searchParams.append(key, router.query[key] as string);
          }
          if (key === "startoverurl" && router.query[key]?.indexOf("?")) {
            const param = (router.query[key] as string).split("?")[1];
            if (param.indexOf("=")) {
              const paramArray = param.split("=");
              url.searchParams.append(paramArray[0], paramArray[1] as string);
            }
          }
        });

        if (startoverurl) {
          const extraSearch = startoverurl.includes("?") ? `&${extraParams.toString()}` : `?${extraParams.toString()}`;
          signInOptions.startoverurl = encodeURIComponent(startoverurl + extraSearch);
        }
        trackMessage("Sign in with identity-server4");
        signIn("identity-server4", { callbackUrl: url.toString() }, signInOptions as SignInAuthorizationParams);
      } else if (status === "authenticated") {
        window.location.href = router.query.callbackUrl
          ? (router.query.callbackUrl as string)
          : `${process.env.NEXT_PUBLIC_APPLICATION_BASE_URL}/`;
      }
    }
  }, [status, router, data]);
};

export default useSignIn;
